var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('a-modal',{staticClass:"m-change-record-modal",attrs:{"title":"修改记录","visible":_setup.visible,"center":"centered","width":"60%","height":"80%"},on:{"cancel":_setup.handleCancel},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('a-button',{on:{"click":_setup.handleCancel}},[_vm._v(" 取消 ")])]},proxy:true}])},[_c('div',{staticClass:"top-option"},[_c('div',{staticClass:"options-btns mr10"},[_c('a-button',{staticClass:"mr20",attrs:{"id":"equipment_change_record_reduction_btn","disabled":!_setup.selectedItem || _setup.isFromGuiGui},nativeOn:{"click":function($event){return _setup.handleReductionParams.apply(null, arguments)}}},[_vm._v(" 还原参数 ")]),(false)?_c('a-button',{attrs:{"id":"equipment_change_record_reset_btn"},nativeOn:{"click":function($event){return _setup.handleResetParams.apply(null, arguments)}}},[_vm._v(" 重置参数 ")]):_vm._e()],1),_c('div',{staticClass:"options-query"},[_c('el-date-picker',{attrs:{"type":"daterange","align":"right","unlink-panels":"","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期","picker-options":_setup.pickerOptions,"prefix-icon":"iconfont icon-time","value-format":"yyyy-MM-dd"},on:{"change":_setup.changeFilterTime},model:{value:(_setup.filterTime),callback:function ($$v) {_setup.filterTime=$$v},expression:"filterTime"}})],1)]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"left-catalogue"},[_c('a-spin',{staticClass:"loading",staticStyle:{"width":"100%"},attrs:{"spinning":_setup.isLoading}},[_vm._l((_setup.catalogueData),function(item){return [_c('div',{key:item.date,staticClass:"title"},[_c('div',[_vm._v(_vm._s(item.date))])]),_vm._l((item.children),function(child,index){return _c('div',{key:child.id,staticClass:"item",class:{
                item: true,
                active: child.id === _setup.selectedItem?.id,
                end: index === item.children.length - 1,
              },on:{"click":() => _setup.handleSelectItem(child, item.date)}},[_vm._v(" "+_vm._s(child.time)+" ")])})]}),(_setup.currentTotal < _setup.total)?_c('div',{staticClass:"more"},[_c('a-button',{attrs:{"type":"link"},on:{"click":_setup.onLoadMore}},[_vm._v(" 加载更多 ")])],1):_vm._e(),(_setup.catalogueData.length === 0 && !_setup.isLoading)?_c('a-empty',{staticClass:"emptyImg absolute",attrs:{"description":"暂无数据","image":require('@/assets/image/noMatchSurplus.png')}}):_vm._e()],2)],1),_c('div',{staticClass:"right-content ml10"},[_c('a-spin',{staticClass:"loading",attrs:{"spinning":_setup.isLoadingRecordData}},[_c('div',{staticClass:"record-list"},[(_setup.isFromGuiGui)?[_c('span',[_vm._v("这条数据在柜柜中保存")])]:_vm._l((_setup.changeRecords),function(item,index){return _c('div',{key:index,staticClass:"record-item"},[_c('span',[_vm._v(_vm._s(index + 1)+"、"+_vm._s(item.path.join('-')))]),(!item.type?.includes('table'))?[_c('span',{staticClass:"oldVal"},[_vm._v(" :"+_vm._s(_setup.dealSpecialValue(item.oldVal, item.path))+" ")]),_c('span',{staticClass:"split-operator"},[_vm._v(">>>>")]),_c('span',{staticClass:"oldVal"},[_vm._v(" "+_vm._s(_setup.dealSpecialValue(item.newVal, item.path))+" ")])]:[(Array.isArray(item.newVal))?_c('div',[_c(_setup.RecordTable,{attrs:{"tableType":item.type,"tData":item.newVal}})],1):_vm._e()]],2)})],2),(_setup.changeRecords.length === 0 && !_setup.isLoadingRecordData)?_c('a-empty',{staticClass:"emptyImg",attrs:{"description":"暂无数据","image":require('@/assets/image/noMatchSurplus.png')}}):_vm._e()],1)],1)])]),_c('a-modal',{attrs:{"title":_setup.confirmTitle,"loading":_setup.isSaving,"centered":""},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('a-button',{key:"back",attrs:{"id":"equipment_change_record_cancel_btn"},on:{"click":() => {
            _setup.confirmVisible = false
          }}},[_vm._v(" 取消 ")]),_c('a-button',{key:"submit",attrs:{"type":"primary","loading":_setup.isSaving,"id":"equipment_change_record_confirm_btn"},on:{"click":_setup.handleConfirm}},[_vm._v(" 确定 ")])]},proxy:true}]),model:{value:(_setup.confirmVisible),callback:function ($$v) {_setup.confirmVisible=$$v},expression:"confirmVisible"}},[_c('div',[_vm._v(_vm._s(_setup.confirmText))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }